body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f7f7f7 !important;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

body, html {
  height: inherit;
}

#root {
  display: flex;
  flex-direction: column;
  flex: 1;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

